// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/app/bg-wallet.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/images/video-tutorial.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "\n.bg-wallet[data-v-449e403f] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 100% 100%;\n  background-repeat: no-repeat;\n  background-size: 100% 100%;\n}\n.bg-tutorial[data-v-449e403f] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-position: 10%;\n  background-repeat: no-repeat;\n  background-size: contain;\n}\n.button-font[data-v-449e403f] {\n  font-size: .875rem;\n}\n", ""]);
// Exports
module.exports = exports;
