var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "section",
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    {
                      staticClass:
                        "px-3 py-4 d-flex flex-row justify-content-between align-items-center bg-tutorial",
                      attrs: { elevation: "0", color: "#EEF4FC" },
                      on: {
                        click: function ($event) {
                          _vm.tutorial = true
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-black f16 font-weight-bold ms-3 w-50",
                        },
                        [_vm._v(_vm._s(_vm.$t("Tutorial")))]
                      ),
                      _c(
                        "v-dialog",
                        {
                          model: {
                            value: _vm.tutorial,
                            callback: function ($$v) {
                              _vm.tutorial = $$v
                            },
                            expression: "tutorial",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            { staticClass: "p-1" },
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "px-2 py-1" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: { small: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.tutorial = false
                                        },
                                      },
                                    },
                                    [_vm._v(" mdi-close ")]
                                  ),
                                ],
                                1
                              ),
                              _c("v-card-text", { staticClass: "py-1 px-2" }, [
                                _c(
                                  "video",
                                  {
                                    key: _vm.videoTutorial,
                                    attrs: {
                                      width: "100%",
                                      height: "100%",
                                      controls: "",
                                    },
                                  },
                                  [
                                    _c("source", {
                                      attrs: {
                                        src: _vm.videoTutorial,
                                        type: "video/mp4",
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    {
                      staticClass:
                        "px-2 py-2 d-flex flex-column justify-content-between bordered",
                      attrs: { elevation: "0", color: "#232126" },
                    },
                    [
                      _c("span", { staticClass: "text-white f13 ms-3" }, [
                        _vm._v(_vm._s(_vm.$t("Your wallet"))),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "bg-wallet pa-2 w-auto d-flex align-items-center justify-content-center",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "text-white py-1 px-6 bg-dark rounded f13",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("VMask")(
                                    _vm.accountBalance,
                                    _vm.currencyMask
                                  )
                                ) +
                                  " " +
                                  _vm._s(_vm.$t("RIAL"))
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("section", { staticClass: "mt-4" }, [
        _c("div", { staticClass: "d-flex justify-content-between" }, [
          _c("div", [
            _c("span", { staticClass: "f16 fw-bold" }, [
              _vm._v(_vm._s(_vm.$t("Schedule a patient"))),
            ]),
          ]),
          _c(
            "div",
            { attrs: { cols: "auto" } },
            [
              _c(
                "button",
                {
                  staticClass:
                    "bg-primary text-white px-2 py-1 button-font rounded",
                  on: { click: _vm.gotoSchedulingPage },
                },
                [_vm._v(" " + _vm._s(_vm.$t("All")) + " ")]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false",
                    },
                  ],
                },
                [_vm._v("| ")]
              ),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false",
                    },
                  ],
                  attrs: { depressed: "", plain: "", tile: "", icon: "" },
                  on: { click: _vm.gotoSchedulingPage },
                },
                [
                  _c("iconly", {
                    staticClass: "pointer primary--text w-100 h-100",
                    attrs: { name: "plus", type: "bold" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm.appointments && _vm.appointments.length > 0
          ? _c(
              "div",
              [
                _c(
                  "v-list",
                  { attrs: { subheader: "", "two-line": "" } },
                  _vm._l(_vm.appointments, function (appointment) {
                    return _c(
                      "v-list-item",
                      { key: appointment.id },
                      [
                        _c(
                          "v-list-item-avatar",
                          [
                            _c("v-avatar", [
                              _c("img", {
                                attrs: {
                                  "lazy-src": require("@/assets/images/profile-circle.png"),
                                  src: appointment.userAppointment.clientUserPic
                                    ? appointment.userAppointment.clientUserPic
                                    : require("@/assets/images/profile.jpg"),
                                  alt: appointment.userAppointment
                                    .clientFullName,
                                },
                              }),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    appointment.userAppointment.clientFullName
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("v-list-item-subtitle", [
                              _vm._v(
                                " " +
                                  _vm._s(appointment.fromTime) +
                                  " - " +
                                  _vm._s(appointment.toTime) +
                                  " "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-action",
                          [
                            _c(
                              "v-btn",
                              { attrs: { icon: "" } },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { color: "green lighten-1" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getUserIcon(
                                            appointment.userAppointment
                                              .sessionType
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        !_vm.appointments || _vm.appointments.length === 0
          ? _c(
              "div",
              { staticClass: "pt-3", attrs: { cols: "12" } },
              [
                _c(
                  "v-card",
                  { attrs: { color: "#EDF6FC", dark: "" } },
                  [
                    _c(
                      "v-card-text",
                      {
                        staticClass: "font-weight-bold",
                        staticStyle: {
                          "text-align": "justify",
                          color: "#2fbded",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("NO_MEETING_SCHEDULED")) + " ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("section", { staticClass: "mt-4" }, [
        _c("div", { staticClass: "d-flex justify-content-between" }, [
          _c("div", [
            _c("span", { staticClass: "f16 fw-bold" }, [
              _vm._v(_vm._s(_vm.$t("Exams"))),
            ]),
          ]),
          _c(
            "div",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "primary--text px-2 py-1",
                  attrs: {
                    color: "primary",
                    depressed: "",
                    plain: "",
                    link: "",
                    "min-width": "auto",
                    "min-height": "auto",
                    height: "auto",
                  },
                  on: { click: _vm.gotoAllExamsPage },
                },
                [_vm._v(" " + _vm._s(_vm.$t("All")) + " ")]
              ),
              _c("span", [_vm._v(" | ")]),
              _c(
                "v-btn",
                {
                  attrs: {
                    depressed: "",
                    plain: "",
                    width: "25",
                    height: "25",
                    small: "",
                    tile: "",
                    icon: "",
                  },
                  on: { click: _vm.gotoAllExamsPage },
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "bg-primary rounded",
                      attrs: { color: "white", small: "" },
                    },
                    [_vm._v(" mdi-plus ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm.exams && _vm.exams.length > 0
          ? _c(
              "div",
              _vm._l(_vm.exams, function (exam, index) {
                return _c(
                  "v-card",
                  {
                    key: index,
                    staticClass: "my-2",
                    attrs: { flat: "", hover: "", ripple: "" },
                    on: {
                      click: function ($event) {
                        return _vm.openExamBottomSheet(exam, index)
                      },
                    },
                  },
                  [
                    _c(
                      "b-list-group",
                      [
                        _c(
                          "b-list-group-item",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c(
                              "v-avatar",
                              { attrs: { color: "red lighten-5", size: "48" } },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "red--text text--lighten-1 f18",
                                  },
                                  [_vm._v(_vm._s(index + 1))]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-column ms-4 me-0 align-items-start justify-content-center",
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "f14 font-weight-bold" },
                                  [_vm._v(_vm._s(exam.ename))]
                                ),
                                _c("span", { staticClass: "f12 grey--text" }, [
                                  _vm._v(
                                    _vm._s(exam.questionCount) +
                                      " " +
                                      _vm._s(_vm.$t("Question"))
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "ms-auto me-0",
                                attrs: {
                                  color: exam.bookmarked
                                    ? "primary"
                                    : "grey darken-4",
                                  icon: "",
                                  outlined: "",
                                  elevation: "0",
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    class: exam.bookmarked
                                      ? "primary--text"
                                      : "grey--text text--darken-4",
                                  },
                                  [_vm._v("mdi-dots-horizontal ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            )
          : _vm._e(),
      ]),
      _c("section", { staticClass: "mt-4" }, [
        _c("div", { staticClass: "d-flex justify-content-between" }, [
          _c("div", [
            _c("span", { staticClass: "f16 fw-bold" }, [
              _vm._v(_vm._s(_vm.$t("Exercises"))),
            ]),
          ]),
          _c(
            "div",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "primary--text px-2 py-1",
                  attrs: {
                    color: "primary",
                    depressed: "",
                    plain: "",
                    link: "",
                    "min-width": "auto",
                    "min-height": "auto",
                    height: "auto",
                  },
                  on: { click: _vm.gotoAllExercisesPage },
                },
                [_vm._v(" " + _vm._s(_vm.$t("All")) + " ")]
              ),
              _c("span", [_vm._v("| ")]),
              _c(
                "v-btn",
                {
                  attrs: {
                    depressed: "",
                    plain: "",
                    width: "25",
                    height: "25",
                    small: "",
                    tile: "",
                    icon: "",
                    to: `/s/exercises/create`,
                  },
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "bg-primary rounded",
                      attrs: { color: "white", small: "" },
                    },
                    [_vm._v(" mdi-plus ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm.exercises && _vm.exercises.length > 0
          ? _c(
              "div",
              _vm._l(_vm.exercises, function (exercise, index) {
                return _c(
                  "v-card",
                  {
                    key: index,
                    staticClass: "my-2",
                    attrs: { flat: "", hover: "", ripple: "" },
                    on: {
                      click: function ($event) {
                        return _vm.openExerciseBottomSheet(exercise, index)
                      },
                    },
                  },
                  [
                    _c(
                      "b-list-group",
                      [
                        _c(
                          "b-list-group-item",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c(
                              "v-avatar",
                              { attrs: { color: "red lighten-5", size: "48" } },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "red--text text--lighten-1 f18",
                                  },
                                  [_vm._v(_vm._s(index + 1))]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-column ms-4 me-0 align-items-start justify-content-center",
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "f14 font-weight-bold" },
                                  [_vm._v(_vm._s(exercise.ename))]
                                ),
                              ]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "ms-auto me-0",
                                attrs: {
                                  color: exercise.bookmarked
                                    ? "primary"
                                    : "grey darken-4",
                                  icon: "",
                                  outlined: "",
                                  elevation: "0",
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    class: exercise.bookmarked
                                      ? "primary--text"
                                      : "grey--text text--darken-4",
                                  },
                                  [_vm._v("mdi-dots-horizontal ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            )
          : _vm._e(),
      ]),
      _c("vue-bottom-sheet", { ref: "examBottomSheet" }, [
        _c(
          "section",
          {
            staticClass:
              "d-flex flex-column align-items-center justify-content-center",
          },
          [
            _c(
              "v-list",
              { staticClass: "w-100", attrs: { rounded: "", dense: "" } },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "text-center mb-1 d-block f14 font-weight-bold",
                  },
                  [_vm._v(_vm._s(_vm.selectedExam.name))]
                ),
                _c(
                  "v-list-item",
                  { on: { click: _vm.handleExamAssign } },
                  [
                    _c(
                      "v-list-item-icon",
                      [
                        _c("iconly", {
                          staticClass: "primary--text",
                          attrs: { name: "adduser", type: "light" },
                        }),
                      ],
                      1
                    ),
                    _c("v-list-item-title", { attrs: { tag: "span" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("Assign to clients")) + " "),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-list-item",
                  { on: { click: _vm.handleExamBookmark } },
                  [
                    _c(
                      "v-list-item-icon",
                      [
                        _c("iconly", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.selectedExam.bookmarked,
                              expression: "!selectedExam.bookmarked",
                            },
                          ],
                          staticClass: "primary--text",
                          attrs: { name: "bookmark", type: "light" },
                        }),
                        _c("iconly", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.selectedExam.bookmarked,
                              expression: "selectedExam.bookmarked",
                            },
                          ],
                          class: "primary--text",
                          attrs: { name: "bookmark", type: "bold" },
                        }),
                      ],
                      1
                    ),
                    !_vm.selectedExam.bookmarked
                      ? _c("v-list-item-title", { attrs: { tag: "span" } }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("Add to favorites")) + " "
                          ),
                        ])
                      : _c("v-list-item-title", { attrs: { tag: "span" } }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("Remove from favorites")) + " "
                          ),
                        ]),
                  ],
                  1
                ),
                _c(
                  "v-list-item",
                  { on: { click: _vm.handleExamPreview } },
                  [
                    _c(
                      "v-list-item-icon",
                      [
                        _c("iconly", {
                          staticClass: "primary--text",
                          attrs: { name: "show", type: "light" },
                        }),
                      ],
                      1
                    ),
                    _c("v-list-item-title", { attrs: { tag: "span" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("Preview")) + " "),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "mt-auto mb-2",
                    attrs: { block: "", color: "primary", rounded: "" },
                    on: { click: _vm.closeExamBottomSheet },
                  },
                  [_vm._v(_vm._s(_vm.$t("Close")) + " ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm.resetedAssignForm
        ? _c(
            "vue-bottom-sheet",
            { ref: "assignExamBottomSheet" },
            [
              _c(
                "v-form",
                {
                  ref: "assignExamForm",
                  staticClass:
                    "d-flex flex-column align-items-center justify-content-center w-100 h-100",
                },
                [
                  _c(
                    "div",
                    { staticClass: "w-100" },
                    [
                      _c("v-text-field", {
                        staticClass: "w-100 border",
                        attrs: {
                          solo: "",
                          flat: "",
                          label: _vm.$t("Search..."),
                          "prepend-inner-icon": "mdi-magnify",
                          "hide-details": "",
                        },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.querySearchFollowersExam.apply(
                              null,
                              arguments
                            )
                          },
                        },
                        model: {
                          value: _vm.searchText,
                          callback: function ($$v) {
                            _vm.searchText = $$v
                          },
                          expression: "searchText",
                        },
                      }),
                    ],
                    1
                  ),
                  !_vm.followersError
                    ? _c(
                        "v-radio-group",
                        {
                          staticClass: "w-100",
                          attrs: {
                            mandatory: "",
                            color: "primary",
                            column: "",
                          },
                          model: {
                            value: _vm.selectedClient,
                            callback: function ($$v) {
                              _vm.selectedClient = $$v
                            },
                            expression: "selectedClient",
                          },
                        },
                        _vm._l(_vm.followers, function (client, index) {
                          return _c("v-radio", {
                            key: index,
                            staticClass: "ltr",
                            attrs: { ripple: "", value: client },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-list-item",
                                        { staticClass: "rtl" },
                                        [
                                          _c(
                                            "v-list-item-avatar",
                                            [
                                              _c("v-img", {
                                                attrs: {
                                                  "lazy-src": require("@/assets/images/profile-circle.png"),
                                                  src:
                                                    client.profilePicture !=
                                                      null &&
                                                    client.profilePicture !== ""
                                                      ? client.profilePicture
                                                      : require("@/assets/images/profile.jpg"),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    `${client.firstName} ${client.lastName}`
                                                  ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        }),
                        1
                      )
                    : _c("div", { staticClass: "my-4" }, [
                        _c("span", [_vm._v(_vm._s(_vm.followersErrorText))]),
                      ]),
                  _c(
                    "div",
                    { staticClass: "w-100 mt-auto" },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: _vm.currencyMask,
                            expression: "currencyMask",
                          },
                        ],
                        key: _vm.keyPrice,
                        staticClass: "text-center",
                        attrs: {
                          outlined: "",
                          light: "",
                          "single-line": "",
                          disabled: _vm.isFree,
                          placeholder: _vm.$t("Exam price"),
                          suffix: _vm.$t("RIAL"),
                          type: "text",
                          rules: [(v) => !!v],
                          required: "",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.price,
                          callback: function ($$v) {
                            _vm.price = $$v
                          },
                          expression: "price",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "w-100" },
                    [
                      _c("v-checkbox", {
                        staticClass: "w-100",
                        attrs: { label: _vm.$t("Free") },
                        model: {
                          value: _vm.isFree,
                          callback: function ($$v) {
                            _vm.isFree = $$v
                          },
                          expression: "isFree",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "my-2 w-100" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { block: "", color: "primary", rounded: "" },
                          on: { click: _vm.submitAssignExam },
                        },
                        [_vm._v(_vm._s(_vm.$t("Submit")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("vue-bottom-sheet", { ref: "exerciseBottomSheet" }, [
        _c(
          "section",
          {
            staticClass:
              "d-flex flex-column align-items-center justify-content-center",
          },
          [
            _c(
              "v-list",
              { staticClass: "w-100", attrs: { rounded: "", dense: "" } },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "text-center mb-1 d-block f14 font-weight-bold",
                  },
                  [_vm._v(_vm._s(_vm.selectedExercise.title))]
                ),
                _c(
                  "v-list-item",
                  { on: { click: _vm.handleExerciseAssign } },
                  [
                    _c(
                      "v-list-item-icon",
                      [
                        _c("iconly", {
                          staticClass: "primary--text",
                          attrs: { name: "adduser", type: "light" },
                        }),
                      ],
                      1
                    ),
                    _c("v-list-item-title", { attrs: { tag: "span" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("Assign to clients")) + " "),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-list-item",
                  { on: { click: _vm.handleExerciseBookmark } },
                  [
                    _c(
                      "v-list-item-icon",
                      [
                        _c("iconly", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.selectedExercise.bookmarked,
                              expression: "!selectedExercise.bookmarked",
                            },
                          ],
                          staticClass: "primary--text",
                          attrs: { name: "bookmark", type: "light" },
                        }),
                        _c("iconly", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.selectedExercise.bookmarked,
                              expression: "selectedExercise.bookmarked",
                            },
                          ],
                          class: "primary--text",
                          attrs: { name: "bookmark", type: "bold" },
                        }),
                      ],
                      1
                    ),
                    !_vm.selectedExercise.bookmarked
                      ? _c("v-list-item-title", { attrs: { tag: "span" } }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("Add to favorites")) + " "
                          ),
                        ])
                      : _c("v-list-item-title", { attrs: { tag: "span" } }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("Remove from favorites")) + " "
                          ),
                        ]),
                  ],
                  1
                ),
                _c(
                  "v-list-item",
                  { on: { click: _vm.handleExercisePreview } },
                  [
                    _c(
                      "v-list-item-icon",
                      [
                        _c("iconly", {
                          staticClass: "primary--text",
                          attrs: { name: "show", type: "light" },
                        }),
                      ],
                      1
                    ),
                    _c("v-list-item-title", { attrs: { tag: "span" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("Preview")) + " "),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-list-item",
                  { on: { click: _vm.handleExerciseEdit } },
                  [
                    _c(
                      "v-list-item-icon",
                      [
                        _c("iconly", {
                          staticClass: "primary--text",
                          attrs: { name: "editsquare", type: "light" },
                        }),
                      ],
                      1
                    ),
                    _c("v-list-item-title", { attrs: { tag: "span" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("Edit")) + " "),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-list-item",
                  { on: { click: _vm.handleExerciseDelete } },
                  [
                    _c(
                      "v-list-item-icon",
                      [
                        _c("iconly", {
                          staticClass: "primary--text",
                          attrs: { name: "delete", type: "light" },
                        }),
                      ],
                      1
                    ),
                    _c("v-list-item-title", { attrs: { tag: "span" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("Delete")) + " "),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "mt-auto mb-2",
                    attrs: { block: "", color: "primary", rounded: "" },
                    on: { click: _vm.closeExerciseBottomSheet },
                  },
                  [_vm._v(_vm._s(_vm.$t("Close")) + " ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("vue-bottom-sheet", { ref: "assignExerciseBottomSheet" }, [
        _c(
          "section",
          {
            staticClass:
              "d-flex flex-column align-items-center justify-content-center",
          },
          [
            _c(
              "div",
              { staticClass: "w-100" },
              [
                _c("v-text-field", {
                  staticClass: "w-100 border",
                  attrs: {
                    solo: "",
                    flat: "",
                    label: _vm.$t("Search..."),
                    "prepend-inner-icon": "mdi-magnify",
                    "hide-details": "",
                  },
                  on: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.querySearchFollowersExercise.apply(
                        null,
                        arguments
                      )
                    },
                  },
                  model: {
                    value: _vm.searchText,
                    callback: function ($$v) {
                      _vm.searchText = $$v
                    },
                    expression: "searchText",
                  },
                }),
              ],
              1
            ),
            !_vm.followersError
              ? _c(
                  "v-radio-group",
                  {
                    staticClass: "w-100",
                    attrs: { mandatory: "", color: "primary", column: "" },
                    model: {
                      value: _vm.selectedClient,
                      callback: function ($$v) {
                        _vm.selectedClient = $$v
                      },
                      expression: "selectedClient",
                    },
                  },
                  _vm._l(_vm.followers, function (client, index) {
                    return _c("v-radio", {
                      key: index,
                      staticClass: "ltr",
                      attrs: { ripple: "", value: client },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c(
                                  "v-list-item",
                                  { staticClass: "rtl" },
                                  [
                                    _c(
                                      "v-list-item-avatar",
                                      [
                                        _c("v-img", {
                                          attrs: {
                                            "lazy-src": require("@/assets/images/profile-circle.png"),
                                            src:
                                              client.profilePicture != null &&
                                              client.profilePicture != ""
                                                ? client.profilePicture
                                                : require("@/assets/images/profile.jpg"),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", {
                                          domProps: {
                                            textContent: _vm._s(
                                              `${client.firstName} ${client.lastName}`
                                            ),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  1
                )
              : _c("div", { staticClass: "my-4" }, [
                  _c("span", [_vm._v(_vm._s(_vm.followersErrorText))]),
                ]),
            _c(
              "div",
              { staticClass: "mt-auto w-100" },
              [
                _c("span", { staticClass: "d-inline-block ms-0 me-2" }, [
                  _vm._v(_vm._s(_vm.$t("Exercise time:"))),
                ]),
                _c("v-otp-input", {
                  staticClass: "d-inline-block w-20",
                  attrs: {
                    length: "1",
                    type: "number",
                    "hide-spin-buttons": "",
                  },
                  model: {
                    value: _vm.availablePeriod,
                    callback: function ($$v) {
                      _vm.availablePeriod = $$v
                    },
                    expression: "availablePeriod",
                  },
                }),
                _c("span", { staticClass: "d-inline-block ms-2 me-0" }, [
                  _vm._v(_vm._s(_vm.$t("Week"))),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "my-2 w-100" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { block: "", color: "primary", rounded: "" },
                    on: { click: _vm.submitAssignExercise },
                  },
                  [_vm._v(_vm._s(_vm.$t("Submit")) + " ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("vue-bottom-sheet", { ref: "previewExerciseBottomSheet" }, [
        _c(
          "span",
          { staticClass: "text-center mb-1 d-block f14 font-weight-bold" },
          [_vm._v(_vm._s(_vm.selectedExercise.title))]
        ),
        _c("p", {
          staticClass: "text-justify",
          domProps: {
            innerHTML: _vm._s(_vm.selectedExercisePreview.description),
          },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }